<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
* {
    font-family: 'Montserrat', sans-serif !important;
}
.line {
  height: 1px;
  background: #fff;
  position: relative;
  z-index: 1;
}
</style>
